import React, { useState } from 'react';
import { loadConnectAndInitialize } from '@stripe/connect-js';
import { ConnectPayouts, ConnectComponentsProvider } from '@stripe/react-connect-js';
import { Card, CardContent, CardHeader } from '@mui/material';
import { hostConfig } from '../../../config';

const PayoutsDash = () => {
  console.log('PayoutsDash - Initial Render');
  console.log('Config:', { hostConfig, publishableKey: process.env.REACT_APP_PUBLISHABLE_PROD_KEY });

  const [stripeConnectInstance] = useState(() => {
    console.log('PayoutsDash - Initializing useState');

    const fetchClientSecret = async () => {
      console.log('fetchClientSecret - Starting request');
      const endpoint = `${hostConfig.THERAPIST_API_BASE}/api/stripe/create-payout-session`;
      console.log('fetchClientSecret - Endpoint:', endpoint);

      const response = await fetch(endpoint, {
        method: "POST"
      });

      console.log('fetchClientSecret - Response status:', response.status);
      
      if (!response.ok) {
        const { error } = await response.json();
        console.error('fetchClientSecret - Error:', error);
        return undefined;
      } else {
        const { clientSecret } = await response.json();
        console.log('fetchClientSecret - Got client secret:', !!clientSecret);
        return clientSecret;
      }
    }

    console.log('PayoutsDash - Calling loadConnectAndInitialize');
    return loadConnectAndInitialize({
      publishableKey: process.env.REACT_APP_PUBLISHABLE_PROD_KEY,
      fetchClientSecret
    });
  });

  console.log('PayoutsDash - Stripe instance exists:', !!stripeConnectInstance);

  return (
    <Card>
      <CardHeader title="Payout Settings & History" />
      <CardContent>
        <ConnectComponentsProvider connectInstance={stripeConnectInstance}>
          <ConnectPayouts />
        </ConnectComponentsProvider>
      </CardContent>
    </Card>
  );
};

export default PayoutsDash;