import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Card, CardContent, Typography, TextField, Button, Rating, Box, Grid } from '@mui/material';
import { hostConfig } from '../../../config';
import { useTranslation } from "react-i18next";

/*
        const mockTherapist = [{
            name: "Dr. Jane Doe",
            uuid: "therapist-uuid",
            sessionUuid: "session-uuid",
            session_type:"first_impression",
            date: "2023/12/23"
        },
        {
            name: "Dr. John Smith",
            uuid: "therapist-uuid-2",
            sessionUuid: "session-uuid-2",
            session_type: "repeating_review",
            date: "2023/12/24"
        }];*/

const ReviewReplyPage = () => {
    const [therapists, setTherapists] = useState([]);
    const [lookingForReply,setLookingForReply] = useState('');
    const [repeatReason,setRepeatReason] = useState('');
    const [generalComment,setGeneralComment] = useState('');
    const [generalRepeatReview, setGeneralRepeatReview] = useState('');
    const { t,i18n } = useTranslation();

    const fetchData = async () => {
        try {
            // Attempt to get data from the endpoint
            const response = await axios.get(`${hostConfig.THERAPIST_API_BASE}/therapistcomments`);
            // If successful, set the data in state
            setTherapists(response.data);
            // Optionally, log the response data to the console for debugging
            console.log('Fetched therapists data:', response.data);
        } catch (error) {
            // Log an error message if the request fails
            console.error('Error fetching therapist data:', error);
            // You may want to set some error state here as well
        }
    };


    useEffect(() => {
        


        // Call the fetchData function
        fetchData();
    }, []); // The empty array ensures this



    const questionBoxStyle = {
        border: '1px solid lightgray',
        borderRadius: '10px',
        padding: '10px',
        marginBottom: '15px',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start' // Aligns content to the left
    };


    const handleSubmit = (therapistData) => {
        // Data to be sent
        const session_uuid = therapistData.session_uuid;
        const therapist_uuid = therapistData.therapist_uuid;
        const patient_uuid = therapistData.patient_uuid;
        const reviewData = {

            session_uuid,
            patient_uuid,
            therapist_uuid,
            lookingForReply,
            generalComment,
            repeatReason,
            generalRepeatReview,
            comment_type: repeatReason ? 'repeat' : 'first_impression',
            commenter_type: "therapist"

        };

        // Endpoint URL of your backend service
        console.log("review data print", reviewData);

        // Sending a POST request to the backend
        axios.post(`${hostConfig.THERAPIST_API_BASE}/commentupdate`, reviewData)
            .then(response => {
                console.log("printingresponse", response.data);
                if (response.data.therapist_update) {
                    fetchData(); // Refresh data if therapist_update is true
                }
                alert('Review Submitted!');
            })
            .catch(error => {
                console.error('There was an error!', error);
            });
    };

    const renderFirstImpressionCard = (therapist) => (
        <Card key={therapist.session_uuid}>
            <CardContent>
                <Typography variant="h5">
                    {t('therapistdashboard.commentsreview.warningtitle')}
                </Typography>
                <Typography variant="body2" gutterBottom style={{ fontSize: '0.8rem', color: 'gray' }}>
                {t('therapistdashboard.commentsreview.warning1')}
                    <br />{t('therapistdashboard.commentsreview.warning2')}
                    {t('therapistdashboard.commentsreview.warning3')}
                    <br />
                </Typography>
                <Box style={questionBoxStyle}>
                    <Typography ><strong>{t('therapistdashboard.commentsreview.reviewfrom')} {therapist.name}</strong></Typography>
                    <Typography variant="body1" gutterBottom align="left">
                        <br></br>
                        {therapist.patient_find_looking_for_fi}

                    </Typography>
                    <TextField
                        label={t('therapistdashboard.commentsreview.generalcomment')}
                        multiline
                        fullWidth
                        margin="normal"
                        value={lookingForReply}
                        onChange={(e) => setLookingForReply(e.target.value)}
                    />
                </Box>
                <Box style={questionBoxStyle}>
                    <Typography variant="body1" gutterBottom align="left">
                        <br></br>
                        {therapist.patient_general_review_fi}

                    </Typography>
                    <TextField
                        label={t('therapistdashboard.commentsreview.wgeneralcomment')}
                        multiline
                        fullWidth
                        margin="normal"
                        value={generalComment}
                        onChange={(e) => setGeneralComment(e.target.value)}
                    />
                </Box>
                <Button variant="contained" color="primary" onClick={() => handleSubmit(therapist)}>
                {t('therapistdashboard.commentsreview.submitbutton')}
                </Button>
            </CardContent>
        </Card>
    );

    const renderRepeatingReviewCard = (therapist) => (
        <Card key={therapist.session_uuid}>
            <CardContent>
                <Typography variant="h5">
                {t('therapistdashboard.commentsreview.warningtitle')}
                </Typography>
                <Typography variant="body2" gutterBottom style={{ fontSize: '0.8rem', color: 'gray' }}>
                {t('therapistdashboard.commentsreview.warning1')}
                    <br />{t('therapistdashboard.commentsreview.warning2')}
                    {t('therapistdashboard.commentsreview.warning3')}
                    <br />
                </Typography>
                <Box style={questionBoxStyle}>
                    <Typography ><strong>{t('therapistdashboard.commentsreview.reviewfrom')} {therapist.name}</strong></Typography>
                    <Typography variant="body1" gutterBottom align="left">
                        <br></br>
                        {therapist.patient_reason_repeat}

                    </Typography>
                    <TextField
                        label={t('therapistdashboard.commentsreview.replytofeedback')}
                        multiline
                        fullWidth
                        margin="normal"
                        value={repeatReason}
                        onChange={(e) => setRepeatReason(e.target.value)}
                    />
                </Box>
                <Box style={questionBoxStyle}>
                    <Typography variant="body1" gutterBottom align="left">
                        <br></br>
                        {therapist.patient_general_review_repeat}

                    </Typography>
                    <TextField
                        label={t('therapistdashboard.commentsreview.generalcomment')}
                        multiline
                        fullWidth
                        margin="normal"
                        value={generalRepeatReview}
                        onChange={(e) => setGeneralRepeatReview(e.target.value)}
                    />
                </Box>
                <Button variant="contained" color="primary" onClick={() => handleSubmit(therapist)}>
                {t('therapistdashboard.commentsreview.submitbutton')}
                </Button>
            </CardContent>
        </Card>
    )


    const renderReviewCard = (therapist) => {
        if (therapist.comment_type === "first_impression") {
            return renderFirstImpressionCard(therapist);
        } else if (therapist.comment_type === "repeating_impression") {
            return renderRepeatingReviewCard(therapist);
        }
    };
    //example pagaraph
    //const paragraph = {paragraph:"In an idyllic countryside, where the rolling hills met the distant horizon, a small village thrived in harmony with nature. Surrounded by lush forests and serene rivers, the villagers lived a life of simplicity and contentment. Days were filled with the gentle rhythms of rural life: the chirping of birds at dawn, the rustling of leaves in the wind, and the laughter of children playing in the meadows. Each evening, as the sun dipped below the hills, painting the sky with hues of orange and purple, the villagers would gather to share stories and enjoy the fruits of their land. In this tranquil setting, time seemed to slow down, allowing everyone to savor the small joys and wonders of everyday life."} 

    return (
        <div style={{ padding: 20, display: 'flex', justifyContent: 'center', gap: '20px' }}>
            <Grid container spacing={2}>
                {therapists && therapists.length > 0 && therapists.map(therapist => (
                    <Grid item xs={12} sm={6} key={therapist.session_uuid}>
                        {renderReviewCard(therapist)}
                    </Grid>
                ))}
            </Grid>
        </div>

    );
}

export default ReviewReplyPage;









