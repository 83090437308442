const prod = {
    API_BASE: "https://www.ascreforme.com",
    THERAPIST_API_BASE: "https://therapists.ascreforme.com",
  };
  
  const dev = {
    API_BASE: "https://www.ascreforme.com",
  };
  
  export const hostConfig = process.env.NODE_ENV === "development" ? dev : prod;
  