import React, { useState, useRef, useEffect } from 'react';
import axios from 'axios';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { v4 as uuidv4 } from 'uuid';
import { hostConfig } from '../../../config';
import EventModal from './EventModal';
import { useTranslation } from "react-i18next";
import moment from 'moment-timezone';
import { 
  Select, 
  MenuItem, 
  FormControl, 
  InputLabel, 
  Typography, 
  Box,
  Tooltip,
  IconButton
} from '@mui/material';
import InfoIcon from '@mui/icons-material/Info';

const getDatesOfWeek = (startDate) => {
  let currentDate = new Date(startDate);
  currentDate.setDate(currentDate.getDate() - currentDate.getDay()); // Ensure it's Sunday

  const dates = Array.from({ length: 7 }, (_, index) => {
    let weekDate = new Date(currentDate);
    weekDate.setDate(weekDate.getDate() + index);
    return moment(weekDate).format('M/D/YYYY');
  });

  return dates;
};

const daysOfWeek = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];

const hoursOfDay = Array.from(new Array(24 * 4), (_, index) => {
  const hour = (Math.floor(index / 4) + 8) % 24;
  const minutes = (index % 4) * 15;
  return `${hour.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}`;
});

const WeekView = () => {
  const [modalOpen, setModalOpen] = useState(false);
  const [eventTitle, setEventTitle] = useState('');
  const [startTime, setStartTime] = useState('');
  const [eventDate, setEventDate] = useState('');
  const [endTime, setEndTime] = useState('');
  const [isOnline, setIsOnline] = useState(false);
  const [invites, setInvites] = useState('');
  const [comments, setComments] = useState('');
  const [modalStyle, setModalStyle] = useState({});

  const [selectedTimeSlot, setSelectedTimeSlot] = useState(null);
  const [selectedTimeSlots, setSelectedTimeSlots] = useState([]);
  const [temporarySelectedCell, setTemporarySelectedCell] = useState(null);
  const [events, setEvents] = useState([]);
  const [editingEventId, setEditingEventId] = useState(null);
  const [patientUuid, setPatientUuid] = useState(null);

  const weekViewRef = useRef();
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const { t, i18n } = useTranslation();

  const [currentWeekStart, setCurrentWeekStart] = useState(new Date());
  const [selectedTimezone, setSelectedTimezone] = useState(moment.tz.guess());

  const timezones = moment.tz.names();

  const goToPreviousWeek = () => {
    setCurrentWeekStart(prevDate => {
      const newDate = new Date(prevDate);
      newDate.setDate(newDate.getDate() - 7);
      return newDate;
    });
  };

  const goToNextWeek = () => {
    setCurrentWeekStart(prevDate => {
      const newDate = new Date(prevDate);
      newDate.setDate(newDate.getDate() + 7);
      return newDate;
    });
  };

  const datesOfWeek = getDatesOfWeek(currentWeekStart);

  const fetchTherapistData = async () => {
    setIsLoading(true);
    setError(null);

    try {
      const response = await axios.get(`${hostConfig.THERAPIST_API_BASE}/gettherapistschedule`);
      const transformedEvents = response.data.map(event => {
        // Parse the timestamp in UTC, then convert to the selected timezone
        const eventDateTime = moment.utc(event.booked_session_date_timestamp).tz(selectedTimezone);
        const formattedDate = eventDateTime.format('M/D/YYYY');
        const formattedTime = eventDateTime.format('HH:mm');
        const dayName = eventDateTime.format('ddd');
  
        return {
          ...event,
          id: event.session_uuid,
          title: "replacement title for now",
          day: dayName,
          date: formattedDate,
          hour: formattedTime,
          startTime: formattedTime,
          endTime: eventDateTime.clone().add(1, 'hour').format('HH:mm'), // Assuming 1-hour sessions
          patient_uuid: event.patient_uuid,
          originalTimestamp: event.booked_session_date_timestamp // Keep the original timestamp for reference
        };
      });

      setEvents(transformedEvents);
      console.log("Transformed Events from front end:", transformedEvents);
    } catch (error) {
      console.error('Error fetching therapist data:', error);
      setError(error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchTherapistData();
  }, [selectedTimezone]);

  const onBeforeCapture = (beforeCapture) => {
    const draggedEvent = events.find(event => event.id === beforeCapture.draggableId);
    if (draggedEvent) {
      console.log(`Event Dragging Starts - Title: ${draggedEvent.title}, UUID: ${draggedEvent.id}, Date: ${draggedEvent.date}, Time: ${draggedEvent.hour}, Index: ${events.findIndex(e => e.id === draggedEvent.id)}`);
    } else {
      console.log("Error: Event not found for draggableId:", beforeCapture.draggableId);
    }
  };

  const onDragEnd = (result) => {
    const { source, destination } = result;
    if (!destination || (source.droppableId === destination.droppableId && source.index === destination.index)) {
      console.log("No change in event position");
      return;
    }

    const [destDay, destHour, destDate] = destination.droppableId.split('-');
    const draggedEvent = events.find(event => event.id === result.draggableId);

    if (!draggedEvent) {
      console.log("Dragged event not found");
      return;
    }

    const oldStartTime = moment.tz(`${draggedEvent.date} ${draggedEvent.startTime}`, selectedTimezone);
    const oldEndTime = moment.tz(`${draggedEvent.date} ${draggedEvent.endTime}`, selectedTimezone);
    const duration = oldEndTime.diff(oldStartTime);

    const newStartTime = moment.tz(`${destDate} ${destHour}`, selectedTimezone);
    const newEndTime = moment(newStartTime).add(duration);

    const formattedNewStartTime = newStartTime.format('HH:mm');
    const formattedNewEndTime = newEndTime.format('HH:mm');

    setEvents(prevEvents => prevEvents.map(event => {
      if (event.id === draggedEvent.id) {
        return {
          ...event,
          day: destDay,
          hour: formattedNewStartTime,
          date: destDate,
          startTime: formattedNewStartTime,
          endTime: formattedNewEndTime,
        };
      }
      return event;
    }));
  };

  const handleCellClick = (day, hour, date, e) => {
    const cellBounds = e.target.getBoundingClientRect();
    const newEventId = uuidv4();
    setTemporarySelectedCell({ day, hour, date });
    setSelectedTimeSlot({ day, hour, date, id: newEventId, uuid: newEventId, startTime: hour });
    setEventTitle('');
    setStartTime(hour);
    setEndTime('');
    setIsOnline(false);
    setInvites('');
    setComments('');
    setModalOpen(true);
    setEditingEventId(null);
    setPatientUuid(null);
    setModalStyle({
      top: `${cellBounds.top}px`,
      left: `${cellBounds.right + 10}px`
    });

    const currentHourIndex = hoursOfDay.findIndex(h => h === hour);
    const nextHourIndex = currentHourIndex + 1 < hoursOfDay.length ? currentHourIndex + 1 : null;
    const nextHour = nextHourIndex !== null ? hoursOfDay[nextHourIndex] : null;

    setSelectedTimeSlots([hour, nextHour].filter(Boolean));
  };

  const handleEventClick = (event, e) => {
    e.stopPropagation();
    const eventIndex = events.findIndex(e => e.id === event.id);

    setEditingEventId(event.id);
    setSelectedTimeSlot({ ...event });
    setEventTitle(event.title);
    setStartTime(event.hour);
    setEndTime(event.endTime);
    setIsOnline(event.isOnline);
    setInvites(event.invites);
    setComments(event.comments);
    setPatientUuid(event.patient_uuid);
    setEventDate(event.date);
    setModalOpen(true);
  };

  const handleCreateEvent = async (selectedTimezone) => {
    const onlineLink = isOnline ? `https://www.ascreforme.com/sessions/${selectedTimeSlot.uuid}` : '';
    
    // Convert event time to UTC for storage
    const eventStartUTC = moment.tz(`${eventDate} ${startTime}`, selectedTimezone).utc().format();
    const eventEndUTC = moment.tz(`${eventDate} ${endTime}`, selectedTimezone).utc().format();
  
    const eventPayload = {
      id: editingEventId || uuidv4(),
      day: moment(eventDate).format('ddd'),
      hour: startTime,
      date: eventDate,
      title: eventTitle,
      startTime: eventStartUTC,
      endTime: eventEndUTC,
      isOnline,
      invites,
      comments,
      patient_uuid: patientUuid,
      timezone: selectedTimezone
    };
  
    try {
      let response;
      if (editingEventId) {
        response = await axios.put(`${hostConfig.THERAPIST_API_BASE}/edittherapistschedule`, eventPayload);
        setEvents(events.map(event => event.id === editingEventId ? { ...event, ...eventPayload } : event));
      } else {
        response = await axios.post(`${hostConfig.THERAPIST_API_BASE}/savetherapistschedule`, eventPayload);
        setEvents([...events, eventPayload]);
      }
      handleClose();
    } catch (error) {
      console.error('Error handling the event:', error);
      handleClose();
    }
  };

  const handleClose = () => {
    setModalOpen(false);
    setTemporarySelectedCell(null);
    setEditingEventId(null);
    setEventTitle('');
    setStartTime('');
    setEndTime('');
    setIsOnline(false);
    setInvites('');
    setComments('');
  };
  const timezoneOptions = [
    { value: 'Asia/Tokyo', label: 'Japan' },
    { value: 'America/New_York', label: 'US Eastern' },
    { value: 'America/Chicago', label: 'US Central' },
    { value: 'America/Denver', label: 'US Mountain' },
    { value: 'America/Los_Angeles', label: 'US Pacific' },
  ];

  const renderTimezoneSelector = () => (
    <Box sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
      <Typography variant="subtitle2" sx={{ mr: 2 }}>
        Select Timezone
      </Typography>
      <FormControl size="small" sx={{ minWidth: 120, mr: 1 }}>
        <Select
          value={selectedTimezone}
          onChange={(e) => setSelectedTimezone(e.target.value)}
          displayEmpty
        >
          {timezoneOptions.map(tz => (
            <MenuItem key={tz.value} value={tz.value}>{tz.label}</MenuItem>
          ))}
        </Select>
      </FormControl>
      <Tooltip title="Times shown will be according to the selected timezone." arrow>
        <IconButton size="small" color="primary">
          <InfoIcon fontSize="small" />
        </IconButton>
      </Tooltip>
    </Box>
  );
  return (
    <DragDropContext onDragEnd={onDragEnd} onBeforeCapture={onBeforeCapture}>
      <div className="therapist_week-view" ref={weekViewRef}>
        {renderTimezoneSelector()}
        <div className="therapist_hour-label"></div>
        <div className="therapist_header-row">
          {daysOfWeek.map((day, i) => (
            <div key={day} className="therapist_day-column-header">
              <div style={{ display: 'flex', alignItems: 'center' }}>
                {day === "Sun" && (
                  <button
                    onClick={goToPreviousWeek}
                    className="arrow-button-schedule-dates"
                  >
                    {"←"}
                  </button>
                )}
                <span>{day}</span>
                {day === "Sat" && (
                  <button
                    onClick={goToNextWeek}
                    className="arrow-button-schedule-dates"
                  >
                    {"→"}
                  </button>
                )}
              </div>
              <div>{datesOfWeek[i]}</div>
            </div>
          ))}
        </div>

        {hoursOfDay.map((hour, hourIndex) => (
          <React.Fragment key={hour}>
            <div className={`therapist_hour-label ${hour.endsWith('00') || hour.endsWith('30') ? '' : 'hidden'}`}>
              {hour}
            </div>
            {daysOfWeek.map((day, dayIndex) => (
              <Droppable droppableId={`${day}-${hour}-${datesOfWeek[dayIndex]}`} key={day}>
                {(provided) => (
                  <div
                    ref={provided.innerRef}
                    {...provided.droppableProps}
                    className={`therapist_hour-cell ${hour.endsWith('00') || hour.endsWith('30') ? '' : 'hide-border'} 
                      ${temporarySelectedCell?.day === day && temporarySelectedCell?.hour === hour && temporarySelectedCell?.date === datesOfWeek[dayIndex] ? 'therapist_schedule_selected' : ''}`}
                    onClick={(e) => handleCellClick(day, hour, datesOfWeek[dayIndex], e)}
                  >
                    {events.filter(event => event.day === day && event.hour === hour && event.date === datesOfWeek[dayIndex])
                      .map((event, index) => {
                        const startTime = moment.tz(`${event.date} ${event.startTime}`, selectedTimezone);
                        const endTime = moment.tz(`${event.date} ${event.endTime}`, selectedTimezone);
                        const durationMinutes = endTime.diff(startTime, 'minutes');
                        const height = (durationMinutes / 30) * 15;
                        const calculatedEndTime = startTime.clone().add(60, 'minutes').format('HH:mm');

                        return (
                          <Draggable key={event.id} draggableId={event.id} index={index}>
                            {(provided, snapshot) => (
                              <div
                                ref={provided.innerRef}
                                {...provided.draggableProps}
                                {...provided.dragHandleProps}
                                className={`therapist_session_event ${snapshot.isDragging ? 'dragging' : ''}`}
                                onClick={(e) => handleEventClick(event, e)}
                              >
                                <div style={{ height: `${height}px` }}>
                                  {event.title || 'Therapist Session'}
                                  <div style={{ fontSize: 'small' }}>
                                    {event.hour} - {calculatedEndTime}
                                  </div>
                                  {event.isOnline && (
                                    <a href={event.onlineLink} target="_blank" rel="noopener noreferrer" style={{ display: 'block', color: 'gray', fontSize: 'small' }}
                                    >
                                      {t('therapistdashboard.scheduler.event.link')}
                                    </a>
                                  )}
                                </div>
                              </div>
                            )}
                          </Draggable>
                        );
                      })}
                    {provided.placeholder}
                  </div>
                )}
              </Droppable>
            ))}
          </React.Fragment>
        ))}


        {/* Modal for event details */}
        <EventModal
          open={modalOpen}
          handleClose={handleClose}
          eventTitle={eventTitle}
          setEventTitle={setEventTitle}
          startTime={startTime}
          setStartTime={setStartTime}
          endTime={endTime}
          setEndTime={setEndTime}
          isOnline={isOnline}
          setIsOnline={setIsOnline}
          invites={invites}
          setInvites={setInvites}
          comments={comments}
          setComments={setComments}
          handleCreateEvent={handleCreateEvent}
          eventDate={eventDate}
          setEventDate={setEventDate}
          modalStyle={modalStyle}
          selectedTimezone={selectedTimezone}
        />
      </div>
    </DragDropContext>
  );


};

export default WeekView;



