// AdminLogin.jsx
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { hostConfig } from '../../config';

const AdminLogin = () => {
 const [email, setEmail] = useState('');
 const [loading, setLoading] = useState(false);
 const [error, setError] = useState('');
 const navigate = useNavigate();

 const handleSubmit = async (e) => {
   e.preventDefault();
   setLoading(true);
   setError('');

   try {
     const response = await axios.post(`${hostConfig.THERAPIST_API_BASE}/admin/login`, {
       therapistEmail: email
     });

     if (response.data.success) {
       // Optional: Store some data in localStorage/sessionStorage if needed
       sessionStorage.setItem('isAdmin', 'true');
       // Redirect to admin dashboard
       navigate('/dashboard');
     }
   } catch (err) {
     setError(
       err.response?.data?.message || 
       'An error occurred. Please try again.'
     );
     console.error('Login error:', err);
   } finally {
     setLoading(false);
   }
 };

 return (
   <div className="min-h-screen flex items-center justify-center bg-gray-50 py-12 px-4 sm:px-6 lg:px-8">
     <div className="max-w-md w-full space-y-8">
       <div>
         <h2 className="mt-6 text-center text-3xl font-extrabold text-gray-900">
           Admin Login
         </h2>
       </div>
       <form className="mt-8 space-y-6" onSubmit={handleSubmit}>
         <div className="rounded-md shadow-sm -space-y-px">
           <div>
             <label htmlFor="email-address" className="sr-only">
               Email address
             </label>
             <input
               id="email-address"
               name="email"
               type="email"
               autoComplete="email"
               required
               className="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-t-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm"
               placeholder="Email address"
               value={email}
               onChange={(e) => setEmail(e.target.value)}
             />
           </div>
         </div>

         {error && (
           <div className="text-red-500 text-sm text-center">
             {error}
           </div>
         )}

         <div>
           <button
             type="submit"
             disabled={loading}
             className={`group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white ${
               loading
                 ? 'bg-indigo-400'
                 : 'bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500'
             }`}
           >
             {loading ? 'Logging in...' : 'Sign in'}
           </button>
         </div>
       </form>
     </div>
   </div>
 );
};

export default AdminLogin;