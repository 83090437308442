import React, { useEffect, useState } from "react";
import axios from "axios";
import { hostConfig } from "../../config.js";
import moment from "moment-timezone";
import { useNavigate } from "react-router-dom";
import { Container, Typography, Button, Select, MenuItem } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import { useTranslation } from "react-i18next";

// Currency formatter utility
const formatCurrency = (amount, currency) => {
    if (!amount) return '';

    try {
        const numericAmount = typeof amount === 'string' ? parseFloat(amount) : amount;

        switch(currency?.toUpperCase()) {
            case 'USD':
                return new Intl.NumberFormat('en-US', {
                    style: 'currency',
                    currency: 'USD',
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2
                }).format(numericAmount / 100); // Convert cents to dollars

            case 'JPY':
                return new Intl.NumberFormat('ja-JP', {
                    style: 'currency',
                    currency: 'JPY',
                    minimumFractionDigits: 0,
                    maximumFractionDigits: 0
                }).format(numericAmount); // JPY doesn't use decimals

            case 'AUD':
                return new Intl.NumberFormat('en-AU', {
                    style: 'currency',
                    currency: 'AUD',
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2
                }).format(numericAmount / 100);

            case 'SGD':
                return new Intl.NumberFormat('en-SG', {
                    style: 'currency',
                    currency: 'SGD',
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2
                }).format(numericAmount / 100);

            default:
                return numericAmount.toString();
        }
    } catch (error) {
        console.error('Error formatting currency:', error);
        return '';
    }
};

const PatientSchedule = () => {
    const [patientSchedules, setPatientSchedules] = useState([]);
    const [pastSessions, setPastSessions] = useState([]);
    const [selectedCurrency, setSelectedCurrency] = useState('USD');
    const [exchangeRates, setExchangeRates] = useState({});
    const navigate = useNavigate();
    const { t } = useTranslation();

    useEffect(() => {
        async function fetchData() {
            try {
                const [schedulesResponse, ratesResponse] = await Promise.all([
                    axios.get(`${hostConfig.API_BASE}/getpatientschedule`),
                    axios.get('https://api.exchangerate-api.com/v4/latest/USD')
                ]);

                            // Log the complete axios responses
                            console.log('Complete schedules response:', schedulesResponse);
                            console.log('Complete rates response:', ratesResponse);
                            
                            // Log just the data portion
                            console.log('Schedules data:', schedulesResponse.data);
                            console.log('Exchange rates data:', ratesResponse.data);
            

                const now = new Date();
                const sortedSchedules = schedulesResponse.data.sort((a, b) =>
                    new Date(a.booked_session_date_timestamp) - new Date(b.booked_session_date_timestamp)
                );

                const upcomingSessions = sortedSchedules.filter(session => 
                    new Date(session.booked_session_date_timestamp) > now
                ).map(session => ({
                    ...session,
                    formattedRate: formatCurrency(session.hourly_rate, session.currency || 'USD')
                }));

                const pastSessions = sortedSchedules.filter(session => 
                    new Date(session.booked_session_date_timestamp) <= now
                ).reverse().map(session => ({
                    ...session,
                    formattedRate: formatCurrency(session.hourly_rate, session.currency || 'USD')
                }));

                setPatientSchedules(upcomingSessions);
                setPastSessions(pastSessions);
                setExchangeRates(ratesResponse.data.rates);
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        }
        fetchData();
    }, []);

    const handleJoinMeeting = (meeting_tokens, session_uuid) => {
        localStorage.setItem('session_uuid', session_uuid);
        navigate(`/patient/room/${session_uuid}`, {
            state: {
                meeting_token: meeting_tokens,
                session_uuid: session_uuid,
            },
        });
    };

    const columns = [
        { 
            field: 'booked_session_date_timestamp', 
            headerName: 'Date and Time', 
            width: 200,
            valueGetter: (params) => {
                console.log('Date column params:', params);
                console.log('Date column row:', params.row);
                return moment(params.booked_session_date_timestamp)
                    .format('YYYY-MM-DD HH:mm');
            }
        },
        { 
            field: 'full_name', 
            headerName: 'Therapist Name', 
            width: 200,
            valueGetter: (params) => params
        },
        { 
            field: 'hourly_rate', 
            headerName: 'Hourly Rate', 
            width: 200,
            valueGetter: (params) => {
                console.log('ValueGetter params:', params);
                console.log('Value:', params.value);
                console.log('Row:', params.row);
                console.log('Data:', params.data);
                console.log('API:', params.api);
        
                // Since params appears to be the direct value
                const rate = params;
                const originalCurrency = params.row?.currency || 'USD';
                
                if (selectedCurrency === originalCurrency) {
                    return formatCurrency(rate, originalCurrency);
                }
        
                if (exchangeRates[selectedCurrency] && exchangeRates[originalCurrency]) {
                    const usdAmount = originalCurrency === 'USD' ? 
                        rate : 
                        rate / exchangeRates[originalCurrency];
                    const convertedAmount = usdAmount * exchangeRates[selectedCurrency];
                    return formatCurrency(convertedAmount, selectedCurrency);
                }
        
                return formatCurrency(rate, originalCurrency);
            },
            renderHeader: (params) => (
                <div style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
                    <span>Hourly Rate</span>
                    <Select
                        value={selectedCurrency}
                        onChange={(e) => setSelectedCurrency(e.target.value)}
                        size="small"
                        style={{ height: '25px' }}
                    >
                        <MenuItem value="USD">USD</MenuItem>
                        <MenuItem value="JPY">JPY</MenuItem>
                        <MenuItem value="AUD">AUD</MenuItem>
                        <MenuItem value="SGD">SGD</MenuItem>
                    </Select>
                </div>
            ),
        }
    ];

    const upcomingColumns = [
        ...columns,
        {
            field: 'meeting_token',
            headerName: 'Join Session',
            width: 150,
            renderCell: (params) => (
                <Button
                    variant="contained"
                    color="primary"
                    size="small"
                    onClick={() => handleJoinMeeting(params.meeting_token, params.session_uuid)}
                    disabled={!params.row || !params.meeting_token}
                >
                    Join
                </Button>
            ),
        },
    ];

    return (
        <Container maxWidth="md">
            <Typography variant="h4" gutterBottom>
                {patientSchedules.length > 0 ? 'Upcoming Sessions' : 'Sessions'}
            </Typography>
            {patientSchedules.length > 0 ? (
                
                <div style={{ height: 400, width: '100%' }}>
                            

                    <DataGrid
                        rows={patientSchedules}
                        columns={upcomingColumns}
                        pageSize={5}
                        rowsPerPageOptions={[5, 10, 20]}
                        getRowId={(row) => row.session_uuid || Math.random().toString()}
                        onRowClick={(params) => console.log('Row data:', params)}

                    />
                </div>
            ) : (
                <div>
                    <Typography variant="h6" gutterBottom>No upcoming sessions scheduled.</Typography>
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={() => navigate('/searchtherapist')}
                        style={{ marginBottom: '20px' }}
                    >
                        Find a Therapist
                    </Button>
                    
                    <Typography variant="h5" gutterBottom>Past Sessions</Typography>
                    {pastSessions.length > 0 ? (
                        <div style={{ height: 400, width: '100%' }}>
                            <DataGrid
                                rows={pastSessions}
                                columns={columns}
                                pageSize={5}
                                rowsPerPageOptions={[5, 10, 20]}
                                getRowId={(row) => row.session_uuid || Math.random().toString()}
                            />
                        </div>
                    ) : (
                        <Typography>No past sessions found.</Typography>
                    )}
                </div>
            )}
        </Container>
    );
};

export default PatientSchedule;