import React from 'react';
import { 
  AppBar, 
  Box, 
  Button, 
  Container, 
  Typography, 
  Toolbar, 
  Grid, 
  Card,
  CardContent,
  CardMedia,
  useScrollTrigger,
} from '@mui/material';
import { 
  CalendarMonth, 
  CreditCard, 
  Videocam, 
  History, 
  BarChart, 
  ArrowForward,
  Star,
  Visibility,
  Psychology
} from '@mui/icons-material';
import { styled } from '@mui/material/styles';
import schedulerImage from './images/scheduler.png';
import paymentsImage from './images/payments.png';
import videoPlatformImage from './images/video platform.png';
import patientHistoryImage from './images/booking.png';
import videoIntroImage from './images/video intro.png';
import reviewsImage from './images/Reviews.png';
import pricingImage from './images/payments.png';  // If you have a specific pricing image
import aiImage from './images/AI.png';

const HeroSection = styled(Box)(({ theme }) => ({
  background: `linear-gradient(rgba(255, 255, 255, 0.9), rgba(255, 255, 255, 0.9)), url('/api/placeholder/1920/1080')`,
  backgroundSize: 'cover',
  backgroundPosition: 'center',
  paddingTop: theme.spacing(15),
  paddingBottom: theme.spacing(10)
}));

const FeatureCard = styled(Card)(({ theme }) => ({
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
  borderRadius: theme.spacing(2),
  overflow: 'hidden',
  boxShadow: '0 4px 12px rgba(0,0,0,0.1)',
  transition: 'transform 0.3s ease-in-out',
  '&:hover': {
    transform: 'translateY(-8px)'
  }
}));

const ImageContainer = styled(Box)({
  position: 'relative',
  '&::after': {
    content: '""',
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    background: 'rgba(0,0,0,0.3)',
  }
});

const ImageOverlay = styled(Typography)(({ theme }) => ({
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  color: 'white',
  fontSize: '2rem',
  fontWeight: 'bold',
  textAlign: 'center',
  width: '100%',
  textShadow: '2px 2px 4px rgba(0,0,0,0.5)',
  zIndex: 2
}));

const ElevationScroll = (props) => {
  const { children } = props;
  const trigger = useScrollTrigger({
    disableHysteresis: true,
    threshold: 0,
  });

  return React.cloneElement(children, {
    elevation: trigger ? 4 : 0,
  });
};

const managementFeatures = [
  {
    title: "Scheduler",
    heading: "Increase Direct Access by 50%",
    description: "Manage all your patients and life through our convenient scheduler",
    icon: <CalendarMonth />,
    imageUrl: schedulerImage,
  },
  {
    title: "Payment Solutions",
    heading: "Reduce Cancellations by 60%",
    description: "Manage your payments through our platform. We don't take a fee",
    subtext: "*3.6% credit card fee applies",
    icon: <CreditCard />,
    imageUrl: paymentsImage,
  },
  {
    title: "Video Platform",
    heading: "Increase Retention by 40%",
    description: "Seamless video consultations with integrated tools and scheduling",
    icon: <Videocam />,
    imageUrl: videoPlatformImage,
  },
  {
    title: "Patient History",
    heading: "All Records in One Place",
    description: "Your record of your patients and notes in one place",
    icon: <History />,
    imageUrl: patientHistoryImage,
  }
];

const growthFeatures = [
  {
    title: "Video Introduction",
    heading: "Connect Instantly",
    description: "Share your story and approach through engaging video introductions",
    icon: <Videocam />,
    imageUrl: videoIntroImage,
  },
  {
    title: "Reviews",
    heading: "Build Trust",
    description: "Showcase authentic patient reviews and testimonials",
    icon: <Star />,
    imageUrl: reviewsImage,
  },
  {
    title: "Visible Pricing",
    heading: "Transparent Fees",
    description: "Clear, upfront pricing to help patients make informed decisions",
    icon: <Visibility />,
    imageUrl: pricingImage,
  },
  {
    title: "Matching AI",
    heading: "Smart Connections",
    description: "Let our AI match you with patients who best fit your expertise",
    icon: <Psychology />,
    imageUrl: aiImage,
  }
];

const LandingPage = () => {
  return (
    <Box sx={{ minHeight: '100vh', bgcolor: '#F2F4E6' }}>
      {/* Navigation */}
      <ElevationScroll>
        <AppBar color="transparent" sx={{ bgcolor: 'rgba(255, 255, 255, 0.8)', backdropFilter: 'blur(8px)' }}>
          <Container maxWidth="lg">
            <Toolbar sx={{ justifyContent: 'space-between' }}>
              <Typography variant="h5" component="div" sx={{ fontWeight: 'bold', color: '#0A6621' }}>
                ASC
              </Typography>
              <Box sx={{ display: 'flex', alignItems: 'center', gap: 3 }}>
                <Button href="#features" color="inherit">Features</Button>
                <Button href="#contact" color="inherit">Contact</Button>
                <Button 
                  variant="contained" 
                  sx={{ 
                    bgcolor: '#0A6621', 
                    '&:hover': { bgcolor: '#085219' } 
                  }}
                >
                  Sign Up
                </Button>
              </Box>
            </Toolbar>
          </Container>
        </AppBar>
      </ElevationScroll>

      {/* Hero Section */}
      <Box sx={{ pt: 12, pb: 8, textAlign: 'center' }}>
        <Container maxWidth="lg">
          <Typography variant="overline" sx={{ color: 'text.secondary', letterSpacing: 2 }}>
            Our Platform
          </Typography>
          <Typography variant="h2" component="h1" sx={{ fontWeight: 'bold', mb: 2 }}>
            Asc for our cutting edge software
          </Typography>
          <Typography variant="h5" sx={{ mb: 4, color: 'text.secondary', maxWidth: 800, mx: 'auto' }}>
            Ascnau offers a range of tools to radically improve your practice, reduce costs and improve efficiency.
          </Typography>
        </Container>
      </Box>

      {/* Management Features Section */}
      <Box sx={{ py: 8, bgcolor: '#d9f1e5' }}>
        <Container maxWidth="lg">
          <Typography 
            variant="h3" 
            component="h2" 
            sx={{ 
              textAlign: 'center', 
              mb: 6, 
              fontWeight: 'bold',
              color: '#0A6621'
            }}
          >
            Manage Your Patients
          </Typography>
          <Grid container spacing={4}>
            {managementFeatures.map((feature) => (
              <Grid item xs={12} sm={6} md={3} key={feature.title}>
                <FeatureCard>
                  <ImageContainer>
                    <CardMedia
                      component="img"
                      height="200"
                      image={feature.imageUrl}
                      alt={feature.title}
                    />
                    <ImageOverlay variant="h4">
                      {feature.title}
                    </ImageOverlay>
                  </ImageContainer>
                  <CardContent sx={{ p: 2 }}>
                    <Typography variant="caption" display="block" sx={{ color: 'text.secondary', mb: 1 }}>
                      {feature.heading}
                    </Typography>
                    <Typography variant="body2" color="text.secondary">
                      {feature.description}
                    </Typography>
                    <Button 
                      size="small"
                      variant="text" 
                      sx={{ 
                        mt: 1,
                        color: '#0A6621',
                        p: 0,
                        '&:hover': { bgcolor: 'transparent', textDecoration: 'underline' }
                      }}
                    >
                      Read More
                    </Button>
                  </CardContent>
                </FeatureCard>
              </Grid>
            ))}
          </Grid>
        </Container>
      </Box>

      {/* Growth Features Section */}
      <Box sx={{ py: 8 }}>
        <Container maxWidth="lg">
          <Typography 
            variant="h3" 
            component="h2" 
            sx={{ 
              textAlign: 'center', 
              mb: 6, 
              fontWeight: 'bold',
              color: '#0A6621'
            }}
          >
            Help Those Who Need You The Most
          </Typography>
          <Grid container spacing={4}>
            {growthFeatures.map((feature) => (
              <Grid item xs={12} sm={6} md={3} key={feature.title}>
                <FeatureCard>
                  <ImageContainer>
                    <CardMedia
                      component="img"
                      height="200"
                      image={feature.imageUrl}
                      alt={feature.title}
                    />
                    <ImageOverlay variant="h4">
                      {feature.title}
                    </ImageOverlay>
                  </ImageContainer>
                  <CardContent sx={{ p: 2 }}>
                    <Typography variant="caption" display="block" sx={{ color: 'text.secondary', mb: 1 }}>
                      {feature.heading}
                    </Typography>
                    <Typography variant="body2" color="text.secondary">
                      {feature.description}
                    </Typography>
                    <Button 
                      size="small"
                      variant="text" 
                      sx={{ 
                        mt: 1,
                        color: '#0A6621',
                        p: 0,
                        '&:hover': { bgcolor: 'transparent', textDecoration: 'underline' }
                      }}
                    >
                      Read More
                    </Button>
                  </CardContent>
                </FeatureCard>
              </Grid>
            ))}
          </Grid>
        </Container>
      </Box>

      {/* CTA Section */}
      <Box sx={{ py: 10 }}>
        <Container maxWidth="lg">
          <Card sx={{ bgcolor: '#0A6621', color: 'white', textAlign: 'center', p: 6, borderRadius: 4 }}>
            <Typography variant="h3" component="h2" gutterBottom>
              Ready to streamline your practice?
            </Typography>
            <Typography variant="h6" sx={{ mb: 4, color: '#E0FFE0' }}>
              Join us today and focus on what matters most - your patients.
            </Typography>
            <Button 
              variant="contained" 
              size="large"
              sx={{ 
                bgcolor: 'white', 
                color: '#0A6621',
                '&:hover': { bgcolor: 'grey.100' },
                px: 4,
                py: 1.5
              }}
            >
              Start Your Journey
            </Button>
          </Card>
        </Container>
      </Box>
    </Box>
  );
};

export default LandingPage;