import React, { useState } from 'react';
import {
  Button,
  Modal,
  Box,
  Typography,
  TextField,
  RadioGroup,
  FormControlLabel,
  Radio,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Select,
  MenuItem
} from '@mui/material';
import axios from 'axios';
import { hostConfig } from '../../../config';

const formatCurrency = (amount, currency) => {
  const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: currency.toUpperCase(),
    minimumFractionDigits: currency.toUpperCase() === 'JPY' ? 0 : 2,
    maximumFractionDigits: currency.toUpperCase() === 'JPY' ? 0 : 2
  });
  return formatter.format(amount / (currency.toUpperCase() === 'JPY' ? 1 : 100));
};

const convertToCurrencyUnit = (amount, currency) => {
  return currency.toUpperCase() === 'JPY' ? Math.round(Number(amount)) : Math.round(Number(amount) * 100);
};

const ChargeButton = ({ patient_uuid, hourly_rate, currency: defaultCurrency, therapist_uuid, patient_full_name, session_uuid }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [chargeType, setChargeType] = useState('hourly');
  const [specialRate, setSpecialRate] = useState('');
  const [selectedCurrency, setSelectedCurrency] = useState(defaultCurrency);
  const [isWarningOpen, setIsWarningOpen] = useState(false);
  const [isLegalWarningOpen, setIsLegalWarningOpen] = useState(false);
  const [isChargeSuccessful, setIsChargeSuccessful] = useState(false);

  const handleOpenModal = () => setIsModalOpen(true);
  const handleCloseModal = () => {
    setIsModalOpen(false);
    setChargeType('hourly');
    setSpecialRate('');
    setSelectedCurrency(defaultCurrency);
  };

  const handleCharge = async () => {
    if (chargeType === 'special') {
      const specialRateInUnits = convertToCurrencyUnit(specialRate, selectedCurrency);
      if (specialRateInUnits > hourly_rate) {
        setIsWarningOpen(true);
        return;
      }
      processCharge(specialRateInUnits);
    } else {
      processCharge(hourly_rate);
    }
  };

  const processCharge = async (amount) => {
    setIsLoading(true);
    try {
      const response = await axios.post(`${hostConfig.THERAPIST_API_BASE}/customercharge`, {
        patient_uuid,
        amount,
        currency: chargeType === 'special' ? selectedCurrency : defaultCurrency,
        session_uuid
      });

      const { success, message } = response.data;

      if (success) {
        alert('Payment processed successfully: ' + message);
        setIsChargeSuccessful(true);
        handleCloseModal();
      } else {
        alert('Payment failed: ' + message);
      }
    } catch (error) {
      console.error('Error:', error);
      if (error.response) {
        alert(`An error occurred while processing the payment: ${error.response.data.error || 'Unknown error'}`);
      } else if (error.request) {
        alert('No response received from the server. Please try again.');
      } else {
        alert(`An error occurred: ${error.message}`);
      }
    } finally {
      setIsLoading(false);
    }
  };

  const handleWarningClose = () => {
    setIsWarningOpen(false);
  };

  const handleWarningConfirm = () => {
    setIsWarningOpen(false);
    setIsLegalWarningOpen(true);
  };

  const handleLegalWarningClose = () => {
    setIsLegalWarningOpen(false);
  };

  const handleLegalWarningConfirm = () => {
    setIsLegalWarningOpen(false);
    processCharge(convertToCurrencyUnit(specialRate, selectedCurrency));
  };

  return (
    <>
      <Button
        onClick={handleOpenModal}
        variant="contained"
        color="primary"
        disabled={isChargeSuccessful}
      >
        {isChargeSuccessful ? 'Charged' : 'Charge'}
      </Button>
      <Modal
        open={isModalOpen}
        onClose={handleCloseModal}
        aria-labelledby="charge-modal-title"
      >
        <Box sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: 400,
          bgcolor: 'background.paper',
          boxShadow: 24,
          p: 4,
        }}>
          <Typography id="charge-modal-title" variant="h6" component="h2">
            Charge {patient_full_name}
          </Typography>
          <RadioGroup
            aria-label="charge-type"
            name="charge-type"
            value={chargeType}
            onChange={(e) => setChargeType(e.target.value)}
          >
            <FormControlLabel
              value="hourly"
              control={<Radio />}
              label={`Hourly Rate (${formatCurrency(hourly_rate, defaultCurrency)})`}
            />
            <FormControlLabel value="special" control={<Radio />} label="Special Rate" />
          </RadioGroup>
          {chargeType === 'special' && (
            <>
              <Box sx={{ display: 'flex', alignItems: 'center', gap: 2, mt: 2 }}>
              <Select
                  value={selectedCurrency}
                  onChange={(e) => setSelectedCurrency(e.target.value)}
                  sx={{ minWidth: 80 }}
                >
                  <MenuItem value="usd">USD</MenuItem>
                  <MenuItem value="eur">EUR</MenuItem>
                  <MenuItem value="gbp">GBP</MenuItem>
                  <MenuItem value="jpy">JPY</MenuItem>
                  {/* Add more currencies as needed */}
                </Select>
                <TextField
                  label="Special Rate"
                  type="number"
                  value={specialRate}
                  onChange={(e) => setSpecialRate(e.target.value)}
                  sx={{ flexGrow: 1 }}
                />

                </Box>
              </>
          )}
              <Button
                onClick={handleCharge}
                disabled={isLoading || (chargeType === 'special' && !specialRate)}
                variant="contained"
                color="primary"
                fullWidth
                sx={{ mt: 2 }}
              >
                {isLoading ? 'Processing...' : 'Confirm Charge'}
              </Button>
            </Box>
      </Modal>
      <Dialog
        open={isWarningOpen}
        onClose={handleWarningClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"Warning: Rate Exceeds Hourly Rate"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            The special rate you've entered ({formatCurrency(convertToCurrencyUnit(specialRate, selectedCurrency), selectedCurrency)})
            exceeds the hourly rate ({formatCurrency(hourly_rate, defaultCurrency)}).
            This will require approval from the customer. Do you want to proceed?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleWarningClose}>Cancel</Button>
          <Button onClick={handleWarningConfirm} autoFocus>
            Proceed
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={isLegalWarningOpen}
        onClose={handleLegalWarningClose}
        aria-labelledby="legal-warning-dialog-title"
        aria-describedby="legal-warning-dialog-description"
      >
        <DialogTitle id="legal-warning-dialog-title">
          {"Legal Warning"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="legal-warning-dialog-description">
            Warning: Charging above the agreed hourly rate without explicit customer approval may have legal ramifications.
            Please ensure you have documented consent from the customer for this higher rate.
            Proceed with caution and only if you are certain you have the customer's agreement.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleLegalWarningClose}>Cancel</Button>
          <Button onClick={handleLegalWarningConfirm} color="error" autoFocus>
            I Understand, Proceed
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default ChargeButton;