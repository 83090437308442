import React from "react";
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
//import AccountInformation from "../../PatientApps/Account/AccountInformation";
//import PatientSchedule from "../../PatientApps/Account/PatientSchedule";
//import TherapistScheduler from "./TherapistScheduler/TherapistScheduler";
import TherapistAccountInformation from "./TherapistInformation";
import Therapist_ClientList from "./ClientList/ClientList";
import CloudinaryUploadWidget from "../Account/TherapistVideoUpload";
import WeekView from "./TherapistScheduler/WeekView";
import TherapistCalendar from "./Calendar/Calendar";
import ReviewReplyPage from "./CommentsReview/CommentsReview";
import { useTranslation } from "react-i18next";
import History from "./History/History";
import TherapistPaymentApp from "./Payments/payments";
import CalendarSchedulerV2 from "./TherapistScheduler/TherapistSchedulerV2";
import PayOutDash from "./Payments/PayOutDash";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3, 
          width: '100%', 
        overflow: 'auto' ,
        border: 1, // MUI's way of saying '1px solid', default color will be from the theme
        borderColor: 'divider', // uses the theme's divider color for the border
      
        }}> {/* Ensure the box takes full width and handles overflow */}
          {children}
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `vertical-tab-${index}`,
    'aria-controls': `vertical-tabpanel-${index}`,
  };
}

export default function TherapistDashboard() {
  const [value, setValue] = React.useState(0);


  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const { t, i18n } = useTranslation();


  return (
    <Box
      sx={{
        flexGrow: 1,
        bgcolor: 'background.paper',
        display: 'flex',
        height: 'auto', // Change fixed height to auto to allow dynamic sizing
        width: '100%',
        mt: 10
      }}
    >
      <Tabs
        orientation="vertical"
        value={value}
        onChange={handleChange}
        aria-label="Vertical tabs example"
        sx={{ borderRight: 1, borderColor: 'divider', }}
        
      >
        
        <Tab label={t('therapistdashboard.schedulertab')} {...a11yProps(0)} />
        <Tab label={t('therapistdashboard.clientlisttab')}{...a11yProps(1)} />
        <Tab label={t('therapistdashboard.historytab')}{...a11yProps(2)} />
        <Tab label={t('therapistdashboard.calendartab')}{...a11yProps(3)} />
        <Tab label={t('therapistdashboard.repliestab')}{...a11yProps(4)} />
        <Tab label={t('therapistdashboard.profilevideotab')}{...a11yProps(5)} />
        <Tab label={t('therapistdashboard.paymentstab')}{...a11yProps(6)} />
        <Tab label={t('therapistdashboard.accounttab')} {...a11yProps(7)} />
        <Tab label={t('therapistdashboard.payouttab')} {...a11yProps(8)} />
        
        

      </Tabs>



      <TabPanel value={value} index={0} className='ScheduleTab'>
      <CalendarSchedulerV2 />
      </TabPanel>

      <TabPanel value={value} index={1} className='ClientList' >
        <Therapist_ClientList />
      </TabPanel>

      <TabPanel value={value} index={2} className='History'>
        <History />
      </TabPanel>

      <TabPanel value={value} index={3} className='CalendarTab'>
        <TherapistCalendar />
      </TabPanel>

      <TabPanel value={value} index={4} className='ReviewReply'>
        <ReviewReplyPage />
      </TabPanel>

      <TabPanel value={value} index={5} className='VideoUpload'>
        <CloudinaryUploadWidget />
      </TabPanel>

      <TabPanel value={value} index={6} className='Payments'>
        <TherapistPaymentApp />
      </TabPanel>

      <TabPanel value={value} index={7} className='AccountInformation' >
        <TherapistAccountInformation />
      </TabPanel>

      <TabPanel value={value} index={8} className='PayoutTab' >
        <PayOutDash/>
      </TabPanel>



    </Box>
  );
}
