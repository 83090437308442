import React, { useState, useCallback, useRef, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import {
  Button,
  IconButton,
  Dialog,
  DialogContent,
  DialogTitle,
  DialogActions,
  TextField,
  Typography,
  CircularProgress,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Box
} from '@mui/material';
import { ChevronLeft, ChevronRight } from '@mui/icons-material';
import moment from 'moment-timezone';
import { hostConfig } from '../../../config';

const timezoneOptions = [
  { value: 'Asia/Tokyo', label: 'Japan' },
  { value: 'America/New_York', label: 'US Eastern' },
  { value: 'America/Chicago', label: 'US Central' },
  { value: 'America/Denver', label: 'US Mountain' },
  { value: 'America/Los_Angeles', label: 'US Pacific' },
];

const mockPatients = [
  { name: 'John Doe', uuid: 'mock-uuid-1' },
  { name: 'Jane Smith', uuid: 'mock-uuid-2' },
  { name: 'Alice Johnson', uuid: 'mock-uuid-3' }
];

const generateMockEvents = () => {
  const events = [];
  const startDate = moment().startOf('day');

  for (let i = 0; i < 5; i++) {
    const eventDate = startDate.clone().add(i, 'days');
    const patient = mockPatients[i % mockPatients.length];
    events.push({
      id: `mock-event-${i}`,
      title: `Session with ${patient.name}`,
      booked_session_date_timestamp: eventDate.hour(9 + i).toISOString(),
      patient_uuid: patient.uuid,
      full_name: patient.name,
      session_uuid: `mock-session-${i}`,
      meeting_token: `mock-token-${i}`
    });
  }
  return events;
};

const EventOverlay = ({ event, rowStart, dayIndex, duration, onClick }) => {
  const cellId = `cell-${rowStart}-${dayIndex}`;
  const cell = document.getElementById(cellId);

  if (!cell) return null;

  const cellRect = cell.getBoundingClientRect();
  const gridRect = cell.parentElement.getBoundingClientRect();

  const top = cellRect.top - gridRect.top + 50;
  const left = cellRect.left - gridRect.left + 50;
  const width = cellRect.width;
  const height = cellRect.height * duration;

  return (
    <div
      style={{
        position: 'absolute',
        top: `${top}px`,
        left: `${left}px`,
        width: `${width}px`,
        height: `${height}px`,
        backgroundColor: '#1b5e20',
        color: 'white',
        padding: '4px',
        fontSize: '12px',
        overflow: 'hidden',
        cursor: 'pointer',
        zIndex: 1,
        borderRadius: '4px',
        transition: 'all 0.3s ease', // Smooth transition for hover effect
      }}
      onClick={() => onClick(event)}
      onMouseEnter={(e) => {
        e.currentTarget.style.backgroundColor = '#2e7d32'; // Lighter green on hover
        e.currentTarget.style.boxShadow = '0 2px 4px rgba(0,0,0,0.2)'; // Add shadow on hover
      }}
      onMouseLeave={(e) => {
        e.currentTarget.style.backgroundColor = '#1b5e20'; // Return to original color
        e.currentTarget.style.boxShadow = 'none'; // Remove shadow
      }}
    >
      {event.title}
    </div>
  );
};



const CalendarSchedulerV2 = ({ onClose }) => {
  const [events, setEvents] = useState([]);
  const [isEventModalOpen, setIsEventModalOpen] = useState(false);
  const [selectedEvent, setSelectedEvent] = useState(null);
  const [eventStart, setEventStart] = useState('');
  const [currentWeekStart, setCurrentWeekStart] = useState(getSunday(new Date()));
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [selectedTimezone, setSelectedTimezone] = useState(timezoneOptions[0].value);
  const [patients, setPatients] = useState([]);
  const [selectedPatient, setSelectedPatient] = useState('');
  const [isGridReady, setIsGridReady] = useState(false);

// Add this new useEffect to handle grid initialization

  const navigate = useNavigate();
  const gridRef = useRef(null);

  const days = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
  const hours = Array.from({ length: 14 }, (_, i) => i + 7); // 7 AM to 8 PM

  useEffect(() => {
    const initializeCalendar = async () => {
      setIsLoading(true);
      try {
        await Promise.all([fetchTherapistData(), fetchPatients()]);
        
        // Check grid readiness after data is loaded
        const checkGridReady = () => {
          const firstCell = document.getElementById('cell-0-0');
          if (firstCell) {
            setIsGridReady(true);
          } else {
            setTimeout(checkGridReady, 100);
          }
        };
        checkGridReady();
      } catch (error) {
        console.error('Error initializing calendar:', error);
      } finally {
        setIsLoading(false);
      }
    };
  
    initializeCalendar();
  }, [currentWeekStart]); // This will run on mount and when week changes
  
  // Add a separate useEffect for timezone changes
  useEffect(() => {
    if (selectedTimezone) {
      fetchTherapistData();
    }
  }, [selectedTimezone]);
  

  const fetchTherapistData = async () => {
    console.log('Fetching therapist data...');
    try {
      let eventsData;
      if (process.env.NODE_ENV === 'development') {
        eventsData = generateMockEvents();
      } else {
        const response = await axios.get(`${hostConfig.THERAPIST_API_BASE}/gettherapistschedule`);
        eventsData = response.data;
      }
      console.log('Raw events data:', eventsData);
  
      const transformedEvents = eventsData.map(event => {
        const startDate = moment.utc(event.booked_session_date_timestamp).tz(selectedTimezone);
        console.log('Processing event:', {
          title: event.title,
          timestamp: event.booked_session_date_timestamp,
          convertedDate: startDate.format()
        });
        
        return {
          ...event,
          start: startDate.toDate(),
          title: `Session with ${event.full_name || 'Unknown'}`,
        };
      });
  
      console.log('Setting transformed events:', transformedEvents);
      setEvents(transformedEvents);
      return transformedEvents;
    } catch (error) {
      console.error('Error fetching therapist data:', error);
      setError(error);
      throw error;
    }
  };



  function getSunday(d) {
    d = new Date(d);
    const day = d.getDay();
    const diff = d.getDate() - day;
    return new Date(d.setDate(diff));
  }

  function formatDate(date) {
    return moment(date).format('MMM D');
  }

  const handleCellClick = (date, hour) => {
    const slotDate = moment.tz(date, selectedTimezone).hour(hour).minute(0);
    setSelectedEvent(null);
    setEventStart(slotDate.format('YYYY-MM-DDTHH:mm'));
    setSelectedPatient('');
    setIsEventModalOpen(true);
  };

  const handleEventClick = (event) => {
    console.log('Event clicked:', event);
    setSelectedEvent(event);
    setEventStart(moment(event.start).tz(selectedTimezone).format('YYYY-MM-DDTHH:mm'));
    
    console.log('Available patients:', patients);
    
    // If the event has a patient_uuid, set it as the selected patient
    if (event.patient_uuid) {
      console.log('Setting patient by UUID:', event.patient_uuid);
      setSelectedPatient(event.patient_uuid);
    } else {
      // If no patient_uuid, try to find the patient by name
      console.log('Looking for patient by name:', event.full_name);
      const patient = patients.find(p => p.full_name === event.full_name);
      console.log('Found patient:', patient);
      if (patient) {
        setSelectedPatient(patient.uuid);
      }
    }
    setIsEventModalOpen(true);
  };
  
  const fetchPatients = async () => {
    if (process.env.NODE_ENV === 'production') {
      try {
        const response = await axios.get(`${hostConfig.THERAPIST_API_BASE}/clientlist`);
        console.log('Raw patient data from API:', response.data);
        
        const formattedPatients = response.data.map(patient => ({
          uuid: patient.patient_uuid,
          full_name: patient.patient_full_name,
          name: patient.patient_full_name 
        }));
        
        console.log('Formatted patients:', formattedPatients);
        setPatients(formattedPatients);
      } catch (error) {
        console.error('Error fetching patients:', error);
      }
    } else {
      const mockPatients = [
        { uuid: 'mock-uuid-1', full_name: 'John Doe', name: 'John Doe' },
        { uuid: 'mock-uuid-2', full_name: 'Jane Smith', name: 'Jane Smith' },
        { uuid: 'mock-uuid-3', full_name: 'Alice Johnson', name: 'Alice Johnson' }
      ];
      console.log('Setting mock patients:', mockPatients);
      setPatients(mockPatients);
    }
  };

  const handleSaveEvent = useCallback(async () => {
    const eventData = {
      patient_uuid: selectedPatient,
      timezone: selectedTimezone,
      startTime: moment(eventStart).utc().format(),
    };

    try {
      if (process.env.NODE_ENV === 'development') {
        console.log('Saving event (development mode):', eventData);
        // Simulate API call in development
        await new Promise(resolve => setTimeout(resolve, 500));
      } else {
        if (selectedEvent) {
          await axios.put(`${hostConfig.THERAPIST_API_BASE}/edittherapistschedule`, {
            ...eventData,
            id: selectedEvent.id,
          });
        } else {
          await axios.post(`${hostConfig.THERAPIST_API_BASE}/savetherapistschedule`, eventData);
        }
      }
      await fetchTherapistData();
    } catch (error) {
      console.error('Error saving event:', error);
    }

    setIsEventModalOpen(false);
    setSelectedEvent(null);
    setEventStart('');
    setSelectedPatient('');
  }, [selectedEvent, eventStart, selectedTimezone, selectedPatient]);

  const handleDeleteEvent = useCallback(async () => {
    if (selectedEvent) {
      try {
        if (process.env.NODE_ENV === 'development') {
          console.log('Deleting event (development mode):', selectedEvent.id);
          // Simulate API call in development
          await new Promise(resolve => setTimeout(resolve, 500));
        } else {
          await axios.post(`${hostConfig.THERAPIST_API_BASE}/deletetherapistschedule`, {
            session_uuid: selectedEvent.id
          });
        }
        await fetchTherapistData();
      } catch (error) {
        console.error('Error deleting event:', error);
      }
      setIsEventModalOpen(false);
      setSelectedEvent(null);
      setEventStart('');
      setSelectedPatient('');
    }
  }, [selectedEvent]);

  const changeWeek = useCallback((increment) => {
    const newDate = moment(currentWeekStart).add(7 * increment, 'days').toDate();
    setCurrentWeekStart(newDate);
  }, [currentWeekStart]);

  const handleJoinMeeting = () => {
    if (selectedEvent && selectedEvent.session_uuid && selectedEvent.meeting_token) {
      navigate(`/session/videochat/${selectedEvent.session_uuid}`, {
        state: {
          token: selectedEvent.meeting_token,
          channel: selectedEvent.session_uuid,
          uid: ''
        }
      });
    } else {
      console.error('Missing session_uuid or meeting_token');
    }
  };


  const renderEventDialog = () => (
    <Dialog open={isEventModalOpen} onClose={() => setIsEventModalOpen(false)}>
      <DialogTitle>
        {selectedEvent ? `Session with ${selectedEvent.full_name}` : 'Create Event'}
      </DialogTitle>
      <DialogContent>
        <TextField
          margin="dense"
          label="Start Time"
          type="datetime-local"
          fullWidth
          value={eventStart}
          onChange={(e) => setEventStart(e.target.value)}
          InputLabelProps={{
            shrink: true,
          }}
          inputProps={{
            step: 1800
          }}
        />
        <FormControl fullWidth margin="dense">
          <InputLabel>Patient</InputLabel>
          <Select
            value={selectedPatient}
            onChange={(e) => setSelectedPatient(e.target.value)}
            label="Patient"
          >
            {patients.map((patient) => (
              <MenuItem key={patient.uuid} value={patient.uuid}>
                {patient.full_name || patient.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <Typography variant="caption">
          Timezone: {timezoneOptions.find(tz => tz.value === selectedTimezone)?.label}
        </Typography>
        {selectedEvent && (
          <Button
            variant="contained"
            color="primary"
            fullWidth
            onClick={handleJoinMeeting}
            style={{ marginTop: '16px' }}
          >
            Join Meeting
          </Button>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={handleSaveEvent}>
          {selectedEvent ? 'Update' : 'Create'}
        </Button>
        {selectedEvent && (
          <Button color="error" onClick={handleDeleteEvent}>
            Delete
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
  return (
    <div>
      <DialogContent>
        {isLoading ? (
          <CircularProgress />
        ) : error ? (
          <Typography color="error">Error loading schedule: {error.message}</Typography>
        ) : (
          <div style={{ padding: '16px' }}>
            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: '16px' }}>
              <IconButton onClick={() => changeWeek(-1)}><ChevronLeft /></IconButton>
              <Typography variant="h6">
                {formatDate(currentWeekStart)} - {formatDate(moment(currentWeekStart).add(6, 'days'))}
              </Typography>
              <IconButton onClick={() => changeWeek(1)}><ChevronRight /></IconButton>
            </div>
            <FormControl size="small" style={{ marginBottom: '16px', minWidth: 120 }}>
              <InputLabel>Timezone</InputLabel>
              <Select
                value={selectedTimezone}
                onChange={(e) => setSelectedTimezone(e.target.value)}
                label="Timezone"
              >
                {timezoneOptions.map((option) => (
                  <MenuItem key={option.value} value={option.value}>{option.label}</MenuItem>
                ))}
              </Select>
            </FormControl>
            <Box
              style={{
                height: '500px',
                overflow: 'auto',
                position: 'relative',
                paddingTop: '50px', // Add space for the sticky header
                paddingLeft: '50px' // Add space for the sticky time column
              }}
              ref={gridRef}
            >
              <div style={{
                display: 'grid',
                gridTemplateColumns: 'auto repeat(7, 1fr)',
                gap: '4px',
                minWidth: '800px',
                position: 'relative'
              }}>
                {/* Sticky header */}
                <div style={{
                  position: 'absolute',
                  top: 0,
                  left: 0,
                  right: 0,
                  height: '50px',
                  backgroundColor: '#fff',
                  zIndex: 2,
                  display: 'grid',
                  gridTemplateColumns: 'auto repeat(7, 1fr)',
                  gap: '4px'
                }}>
                  <div style={{ backgroundColor: '#fff' }}></div> {/* Empty cell for top-left corner */}
                  {days.map((day, index) => {
                    const date = moment(currentWeekStart).add(index, 'days');
                    return (
                      <div key={day} style={{ textAlign: 'center', backgroundColor: '#fff' }}>
                        <Typography variant="subtitle2" style={{ fontWeight: 'bold' }}>{day}</Typography>
                        <Typography variant="caption">{formatDate(date)}</Typography>
                      </div>
                    );
                  })}
                </div>

                {/* Sticky time column */}
                <div style={{
                  position: 'absolute',
                  top: '50px',
                  left: 0,
                  width: '50px',
                  bottom: 0,
                  backgroundColor: '#fff',
                  zIndex: 2,
                  overflowY: 'hidden'
                }}>
                  {hours.map(hour => (
                    <Typography
                      key={hour}
                      variant="body2"
                      style={{
                        textAlign: 'right',
                        paddingRight: '8px',
                        height: '48px',
                        lineHeight: '48px'
                      }}
                    >
                      {`${hour}:00`}
                    </Typography>
                  ))}
                </div>

                {/* Main grid content */}
                {hours.map((hour, rowIndex) => (
                  <React.Fragment key={hour}>
                    <div style={{ height: '48px' }}></div> {/* Placeholder for time column */}
                    {days.map((_, dayIndex) => (
                      <div
                        key={`cell-${rowIndex}-${dayIndex}`}
                        id={`cell-${rowIndex}-${dayIndex}`}
                        style={{
                          border: '1px solid #e0e0e0',
                          cursor: 'pointer',
                          height: '48px',
                          position: 'relative'
                        }}
                        onClick={() => handleCellClick(moment(currentWeekStart).add(dayIndex, 'days').hour(hour).toDate(), hour)}
                      />
                    ))}
                  </React.Fragment>
                ))}
              </div>

              {/* Event overlays */}
              {isGridReady && events.map(event => {
  const startDate = moment(event.start);
  const rowStart = startDate.hour() - 7;
  const dayIndex = startDate.diff(moment(currentWeekStart), 'days');
  const duration = 1;

  console.log('Rendering event:', {
    title: event.title,
    startDate: startDate.format(),
    rowStart,
    dayIndex,
    currentWeekStart: moment(currentWeekStart).format()
  });

  return (
    <EventOverlay
      key={event.id}
      event={event}
      rowStart={rowStart}
      dayIndex={dayIndex}
      duration={duration}
      onClick={handleEventClick}
    />
  );
})}
            </Box>
          </div>
        )}
      </DialogContent>

      {renderEventDialog()}
    </div>
  );
};

export default CalendarSchedulerV2;