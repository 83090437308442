import React, { useState, useEffect } from 'react';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import axios from 'axios';
import { hostConfig } from '../../../config';
import { useTranslation } from "react-i18next";



const PatientList = () => {
    const names = ['Alison', 'Ferris', 'David', 'Daniel'];
    const { t,i18n } = useTranslation();
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true);
  
    useEffect(() => {
        const fetchData = async () => {
          try {
            // Replace with your API endpoint
            const response = await axios.get(`${hostConfig.THERAPIST_API_BASE}/clientlist`);

            const uuidSet = new Set();
            const uniquePatientNames = response.data
              .filter(patient => {
                const isDuplicate = uuidSet.has(patient.patient_uuid);
                uuidSet.add(patient.patient_uuid);
                return !isDuplicate;
              })
              .map(patient => patient.patient_full_name); // Extracting only patient_full_name
            console.log("uniquePatients",uniquePatientNames);
    
            setData(uniquePatientNames);
            setLoading(false);
          } catch (error) {
            console.error('Error fetching data: ', error);
            setLoading(false);
          }
        };
    
        fetchData();
      }, []);
      //console.log("data: ",data);//checking payload

    return (
        <div>

          <Autocomplete
            freeSolo
            options={data}
            getOptionLabel={(option) => option} // Adjust depending on your data structure
            renderInput={(params) => (
                <TextField
                {...params}
                label={loading ? t('therapistdashboard.scheduler.event.loadingpatient') : t('therapistdashboard.scheduler.event.loadingpatient')}
                margin="normal"
                fullWidth
              />
            )}
          />
        
      </div>
    );
  };

export default PatientList;
