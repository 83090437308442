import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Container, Box, Button, Typography, TextField, CircularProgress, Alert } from '@mui/material';
import { hostConfig } from '../../../config';

const WithdrawalForm = () => {
  const [maxWithdrawalAmount, setMaxWithdrawalAmount] = useState(0);
  const [amount, setAmount] = useState('');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [currency, setCurrency] = useState('jpy'); // Default currency

  useEffect(() => {
    // Fetch max withdrawal amount and currency
    const fetchMaxWithdrawalAmount = async () => {
      try {
        const response = await axios.get(`${hostConfig.THERAPIST_API_BASE}/get-max-withdrawal-amount`);
        setMaxWithdrawalAmount(response.data.maxWithdrawalAmount);
        setCurrency(response.data.currency); // Set currency from backend response
      } catch (error) {
        console.error('Error fetching max withdrawal amount:', error);
      }
    };

    fetchMaxWithdrawalAmount();
  }, []);

  const handleSubmit = async (event) => {
    event.preventDefault();
    setError(null);

    // Frontend validation for maximum withdrawal amount
    if (amount * 100 > maxWithdrawalAmount) {
      setError(`The amount exceeds the maximum withdrawal limit of ${currency} ${maxWithdrawalAmount / 100}`);
      return;
    }

    setLoading(true);
    try {
      const response = await axios.post(`${hostConfig.THERAPIST_API_BASE}/create-payout`, {
        amount: amount * 100, // Convert to cents if needed
        currency,
      });
      setAmount('');
      // Fetch updated max withdrawal amount
      const maxAmountResponse = await axios.get(`${hostConfig.THERAPIST_API_BASE}/get-max-withdrawal-amount`);
      setMaxWithdrawalAmount(maxAmountResponse.data.maxWithdrawalAmount);
      setCurrency(maxAmountResponse.data.currency);
      setError(null);
    } catch (error) {
      console.error('Error creating payout:', error);
      setError(error.response ? error.response.data.error : 'Unknown error');
    } finally {
      setLoading(false);
    }
  };

  return (
    <Container maxWidth="sm">
      <Box component="form" onSubmit={handleSubmit} sx={{ mt: 4 }}>
        <Typography variant="h5" component="h2" gutterBottom>
          Request Payout
        </Typography>
        <Typography variant="body1" sx={{ mb: 2 }}>
          Maximum Withdrawal Amount: {currency} {maxWithdrawalAmount / 100}
        </Typography>
        <TextField
          label="Amount"
          fullWidth
          margin="normal"
          value={amount}
          onChange={(e) => setAmount(e.target.value)}
          type="number"
          inputProps={{ max: maxWithdrawalAmount / 100 }}
        />
        <Button type="submit" variant="contained" color="primary" sx={{ mt: 2 }} disabled={loading}>
          {loading ? <CircularProgress size={24} /> : 'Request Payout'}
        </Button>
        {error && (
          <Alert severity="error" sx={{ mt: 2 }}>
            {error}
          </Alert>
        )}
      </Box>
    </Container>
  );
};

export default WithdrawalForm;
