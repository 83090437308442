import React, { useState, useEffect } from 'react';
import { Calendar, momentLocalizer } from 'react-big-calendar';
import moment from 'moment';
import axios from 'axios';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import { hostConfig } from '../../../config';

const localizer = momentLocalizer(moment);

function TherapistCalendar() {
    const [events, setEvents] = useState([]);

    useEffect(() => {
        const fetchEvents = async () => {
            try {
                const response = await axios.get(`${hostConfig.THERAPIST_API_BASE}/gettherapistschedule`); // Replace with your API endpoint
                const sessions = response.data; // Adjust this based on the response structure

                const calendarEvents = sessions.map(convertSessionToEvent);
                setEvents(calendarEvents);
            } catch (error) {
                console.error('Error fetching events:', error);
                // Handle error appropriately
            }
        };

        fetchEvents();
    }, []); // Empty dependency array to run only on component mount

    return (
        <div style={{ height: 700 }}>
            <Calendar
                localizer={localizer}
                events={events}
                startAccessor="start"
                endAccessor="end"
                style={{ height: 500 }}
                views={{ month: true }}
            />
        </div>
    );
}

function convertSessionToEvent(session) {
    const startDate = new Date(session.booked_session_date_timestamp);
    const endDate = new Date(startDate.getTime() + 60 * 60 * 1000); // Add 1 hour in milliseconds

    return {
        title: 'Session',
        start: startDate,
        end: endDate
    };
}

export default TherapistCalendar;
