import React, { useState } from 'react';
import axios from 'axios';
import { 
    TextField, 
    Button, 
    Paper, 
    Typography, 
    Box, 
    Alert,
    Stepper,
    Step,
    StepLabel 
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { hostConfig } from '../../config';

// Step components
const EmailStep = ({ email, setEmail, onSubmit, isLoading, error }) => (
    <form onSubmit={onSubmit}>
        <TextField
            fullWidth
            label="Email Address"
            type="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            margin="normal"
            required
            autoFocus
        />
        <Button
            fullWidth
            variant="contained"
            type="submit"
            disabled={isLoading}
            sx={{ mt: 3 }}
        >
            {isLoading ? 'Sending...' : 'Send Verification Code'}
        </Button>
        {error && (
            <Alert severity="error" sx={{ mt: 2 }}>
                {error}
            </Alert>
        )}
    </form>
);

const VerifyCodeStep = ({ code, setCode, onSubmit, isLoading, error }) => (
    <form onSubmit={onSubmit}>
        <TextField
            fullWidth
            label="Verification Code"
            value={code}
            onChange={(e) => setCode(e.target.value)}
            margin="normal"
            required
            autoFocus
        />
        <Button
            fullWidth
            variant="contained"
            type="submit"
            disabled={isLoading}
            sx={{ mt: 3 }}
        >
            {isLoading ? 'Verifying...' : 'Verify Code'}
        </Button>
        {error && (
            <Alert severity="error" sx={{ mt: 2 }}>
                {error}
            </Alert>
        )}
    </form>
);

const ResetPasswordStep = ({ passwords, setPasswords, onSubmit, isLoading, error }) => (
    <form onSubmit={onSubmit}>
        <TextField
            fullWidth
            margin="normal"
            label="New Password"
            type="password"
            value={passwords.newPassword}
            onChange={(e) => setPasswords({
                ...passwords,
                newPassword: e.target.value
            })}
            required
        />
        <TextField
            fullWidth
            margin="normal"
            label="Confirm New Password"
            type="password"
            value={passwords.confirmPassword}
            onChange={(e) => setPasswords({
                ...passwords,
                confirmPassword: e.target.value
            })}
            required
        />
        <Button
            fullWidth
            variant="contained"
            type="submit"
            disabled={isLoading}
            sx={{ mt: 3 }}
        >
            {isLoading ? 'Updating...' : 'Reset Password'}
        </Button>
        {error && (
            <Alert severity="error" sx={{ mt: 2 }}>
                {error}
            </Alert>
        )}
    </form>
);

// Main component
const TherapistResetPassword = () => {
    const navigate = useNavigate();
    const [activeStep, setActiveStep] = useState(0);
    const [email, setEmail] = useState('');
    const [code, setCode] = useState('');
    const [passwords, setPasswords] = useState({
        newPassword: '',
        confirmPassword: ''
    });
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState('');
    const [resetToken, setResetToken] = useState('');

    const steps = ['Enter Email', 'Verify Code', 'Reset Password'];

    const handleEmailSubmit = async (e) => {
        e.preventDefault();
        setIsLoading(true);
        setError('');

        try {
            const response = await axios.post(`${hostConfig.THERAPIST_API_BASE}/therapist-initiate-reset`, {
                email
            });
            setActiveStep(1);
        } catch (error) {
            setError(error.response?.data?.error || 'Failed to send verification code');
        } finally {
            setIsLoading(false);
        }
    };

    const handleVerifyCode = async (e) => {
        e.preventDefault();
        setIsLoading(true);
        setError('');

        try {
            const response = await axios.post(`${hostConfig.THERAPIST_API_BASE}/therapist-verify-reset-code`, {
                email,
                code
            });
            setResetToken(response.data.resetToken);
            setActiveStep(2);
        } catch (error) {
            setError(error.response?.data?.error || 'Invalid verification code');
        } finally {
            setIsLoading(false);
        }
    };

    const handlePasswordReset = async (e) => {
        e.preventDefault();
        setIsLoading(true);
        setError('');

        if (passwords.newPassword !== passwords.confirmPassword) {
            setError('Passwords do not match');
            setIsLoading(false);
            return;
        }

        try {
            await axios.post(`${hostConfig.THERAPIST_API_BASE}/therapist-reset-password`, {
                resetToken,
                newPassword: passwords.newPassword
            });
            
            // Show success message and redirect
            navigate('/therapistlogin', { 
                state: { message: 'Password successfully reset. Please login with your new password.' } 
            });
        } catch (error) {
            setError(error.response?.data?.error || 'Failed to reset password');
        } finally {
            setIsLoading(false);
        }
    };

    const getStepContent = (step) => {
        switch (step) {
            case 0:
                return (
                    <EmailStep
                        email={email}
                        setEmail={setEmail}
                        onSubmit={handleEmailSubmit}
                        isLoading={isLoading}
                        error={error}
                    />
                );
            case 1:
                return (
                    <VerifyCodeStep
                        code={code}
                        setCode={setCode}
                        onSubmit={handleVerifyCode}
                        isLoading={isLoading}
                        error={error}
                    />
                );
            case 2:
                return (
                    <ResetPasswordStep
                        passwords={passwords}
                        setPasswords={setPasswords}
                        onSubmit={handlePasswordReset}
                        isLoading={isLoading}
                        error={error}
                    />
                );
            default:
                return null;
        }
    };

    return (
        <Box
            sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                minHeight: '100vh',
                padding: 2
            }}
        >
            <Paper 
                elevation={3} 
                sx={{ 
                    p: 4, 
                    width: '100%', 
                    maxWidth: 500 
                }}
            >
                <Typography 
                    variant="h5" 
                    component="h1" 
                    gutterBottom
                    textAlign="center"
                >
                    Reset Password
                </Typography>

                <Stepper activeStep={activeStep} sx={{ mb: 4 }}>
                    {steps.map((label) => (
                        <Step key={label}>
                            <StepLabel>{label}</StepLabel>
                        </Step>
                    ))}
                </Stepper>

                {getStepContent(activeStep)}

                <Button
                    fullWidth
                    variant="text"
                    onClick={() => navigate('/login')}
                    sx={{ mt: 2 }}
                >
                    Back to Login
                </Button>
            </Paper>
        </Box>
    );
};

export default TherapistResetPassword;