import React, { useState } from 'react';
import axios from 'axios';
import {
  Grid,
  TextField,
  Button,
  Card,
  CardContent,
  Typography,
  Alert,
  FormControlLabel,
  Checkbox,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
  Box,
  Divider,
  Snackbar
} from "@mui/material";
import { useNavigate } from 'react-router-dom';
import { hostConfig } from '../../config';
import { useTranslation } from "react-i18next";
import GoogleIcon from '@mui/icons-material/Google';
import GTherapistRegistration from './GRegistrationTherapist';
import LineAuthButton from './LineRegistration';

const TherapistRegistrationForm = () => {
  const [therapistFirstname, setTherapistFirstname] = useState('');
  const [therapistLastname, setTherapistLastname] = useState('');
  const [therapistPassword, setTherapistPassword] = useState('');
  const [therapistEmail, setTherapistEmail] = useState('');
  const [preferredTimezone, setPreferredTimezone] = useState('');
  const [error, setError] = useState('');
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const navigate = useNavigate();
  const { t } = useTranslation();

  const validateEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const validatePassword = (password) => {
    const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\W).{6,}$/;
    return passwordRegex.test(password);
  };

  const isFormValid = () => {
    return validateEmail(therapistEmail) && validatePassword(therapistPassword) && preferredTimezone;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!isFormValid()) {
      console.log('Form is invalid');
      return;
    }

    try {
      const response = await axios.post(`${hostConfig.THERAPIST_API_BASE}/therapistregistration`,
        { therapistFirstname, therapistLastname, therapistEmail, therapistPassword, preferredTimezone });

      if (response.data.emailExist) {
        setError('An account with this email already exists.');
      } else if (!response.data.userCreated) {
        setError('Error occurred while creating an account.');
      } else if (response.data.userCreated) {
        setSnackbarOpen(true);
        setTimeout(() => {
          navigate("/therapistlogin");
        }, 2000);
      }
    } catch (error) {
      if (error.response) {
        console.log('Error occurred while registering:', error.response.data);
        setError('Registration failed: ' + error.response.data.message);
      } else if (error.request) {
        console.log('Error occurred: No response received');
        setError('Network error: No response received');
      } else {
        console.log('Error occurred:', error.message);
        setError('Error: ' + error.message);
      }
    }
  };

  const handleSnackbarClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setSnackbarOpen(false);
  };

  return (
    <Box sx={{
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      minHeight: '100vh',
      backgroundColor: '#f5f5f5'
    }}>
      <Card sx={{
        maxWidth: 500,
        width: '100%',
        boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
        borderRadius: '10px'
      }}>
        <CardContent sx={{ padding: '40px' }}>
          <Typography variant="h4" gutterBottom sx={{ fontWeight: 'bold', color: '#333' }}>
            {t('therapistregistration.title')}
          </Typography>
          {error && <Alert severity="error" sx={{ mb: 2 }}>{error}</Alert>}
          <form onSubmit={handleSubmit}>
            <Grid container spacing={3}>
              <Grid item xs={12} sm={6}>
                <TextField
                  label={t('therapistregistration.firstname')}
                  variant="outlined"
                  value={therapistFirstname}
                  onChange={(e) => setTherapistFirstname(e.target.value)}
                  fullWidth
                  required
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  label={t('therapistregistration.lastname')}
                  variant="outlined"
                  value={therapistLastname}
                  onChange={(e) => setTherapistLastname(e.target.value)}
                  fullWidth
                  required
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  label={t('therapistregistration.email')}
                  variant="outlined"
                  value={therapistEmail}
                  onChange={(e) => setTherapistEmail(e.target.value)}
                  fullWidth
                  required
                  error={!validateEmail(therapistEmail)}
                  helperText={!validateEmail(therapistEmail) && therapistEmail ? "Invalid Email" : ""}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  label={t('therapistregistration.password')}
                  variant="outlined"
                  type="password"
                  value={therapistPassword}
                  onChange={(e) => setTherapistPassword(e.target.value)}
                  fullWidth
                  required
                  error={!validatePassword(therapistPassword)}
                  helperText={!validatePassword(therapistPassword) && therapistPassword ? "Password must be at least 6 characters and include uppercase, lowercase, and special characters." : ""}
                />
              </Grid>
              <Grid item xs={12}>
                <FormControl fullWidth>
                  <InputLabel id="timezone-label">Preferred Timezone</InputLabel>
                  <Select
                    labelId="timezone-label"
                    value={preferredTimezone}
                    onChange={(e) => setPreferredTimezone(e.target.value)}
                    label="Preferred Timezone"
                    required
                  >
                    <MenuItem value="America/Los_Angeles">Pacific Time (PT)</MenuItem>
                    <MenuItem value="America/New_York">Eastern Time (ET)</MenuItem>
                    <MenuItem value="UTC">Coordinated Universal Time (UTC)</MenuItem>
                    <MenuItem value="Europe/Paris">Central European Time (CET)</MenuItem>
                    <MenuItem value="Asia/Shanghai">China Standard Time (CST)</MenuItem>
                    <MenuItem value="Asia/Tokyo">Japan Standard Time (JST)</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12}>
                <FormControlLabel
                  control={<Checkbox />}
                  label={t('therapistregistration.agree')}
                />
              </Grid>
              <Grid item xs={12}>
                <Button
                  size="large"
                  type="submit"
                  variant="contained"
                  fullWidth
                  disabled={!isFormValid()}
                  sx={{
                    mt: 2,
                    backgroundColor: '#4CAF50',
                    '&:hover': {
                      backgroundColor: '#45a049',
                    },
                  }}
                >
                  {t('therapistregistration.submit')}
                </Button>
              </Grid>
            </Grid>
          </form>
          <Divider sx={{ my: 3 }}>
            <Typography variant="body2" color="textSecondary">
              Or
            </Typography>
          </Divider>
          <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
            <GTherapistRegistration preferredTimezone={preferredTimezone} />
            <LineAuthButton userType="therapist" />
          </Box>
        </CardContent>
      </Card>
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={2000}
        onClose={handleSnackbarClose}
        message="Account created successfully! Redirecting to login..."
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      />
    </Box>
  );
};

export default TherapistRegistrationForm;